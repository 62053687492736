.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-list {
  background-color: white;
}

.header {
  background-color: white;
}

a {
  color: #2f55d4;;
}

a:hover {
  color:#2443ac;
}

.ant-btn {
  font-size: 14px;
  line-height: 24px;
  /*letter-spacing: 0.2px;*/
  /*border-radius: 6px;*/
  box-shadow: 0 3px 5px 0 rgb(47 85 212 / 30%);
  /*font-weight: 600*/;
}

.ant-btn-primary {
  background-color: #2f55d4;;
  border-color: #2f55d4;
}

.ant-btn-primary:hover {
  background-color:#2443ac !important;
}

.selected-card {
  background-color: #2443ac;
}